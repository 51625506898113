<template>
  <div class="contract-info-box">
    <v-divider></v-divider>

    <div class="contract-info-box__row">
      <span class="row__title">{{ $t('general.creator') }}:</span>
      <span class="row__data">
        {{ contract.creator.full_name }}
      </span>
    </div>

    <div class="contract-info-box__row">
      <span class="row__title">{{ $t('general.created_at') }}:</span>
      <span class="row__data">
        {{ contract.date | formatDate }}
      </span>
    </div>

    <div class="contract-info-box__row">
      <span class="row__title">{{ $t('general.valid_till') }}</span>
      <span class="row__data">
        {{ contract.pending_expiration_date | formatDate }}
      </span>
    </div>

    <div class="contract-info-box__row">
      <span class="row__title">{{ $t('contract.one_device_sign') }}:</span>
      <span class="row__data">
        {{ contract.one_device ? $t('general.yes') : $t('general.no') }}
      </span>
    </div>

    <div class="contract-info-box__row">
      <span class="row__title"> {{ $t('contract.internal_document_number') }}: </span>
      <span class="row__data">
        {{ internalDocumentNumber }}
      </span>
    </div>

    <div class="contract-info-box__row">
      <span class="row__title">{{ $t('contract.presumed_delivery') }}:</span>
      <span class="row__data">
        {{ contract.hasPresumedDelivery ? $t('general.yes') : $t('general.no') }}
      </span>
    </div>

    <div v-if="!isCounterparty && userCanEditContract" class="contract-info-box__row">
      <a href="#" @click.prevent="editContract()">
        {{ $t('contract.edit') }}
      </a>
    </div>

    <div v-if="documentArchivingExpirationDate" class="contract-info-box__row">
      <span class="row__title" v-html="documentArchivingExpirationDate"></span>
    </div>
    <div v-if="documentInArchiveTrashDate" class="contract-info-box__row">
      <span class="row__title" v-html="documentInArchiveTrashDate"></span>
    </div>
  </div>
</template>

<script>
import { getFormattedDateByLocale } from '@/common/reusable/dateFunctions'
import { parseDate } from '@/services/helpers/parseDate'

export default {
  name: 'ContractDocumentInfoBox',
  props: {
    contract: {
      type: Object,
      required: true,
    },
    isCounterparty: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatDate: (jsDate) => (jsDate ? parseDate(jsDate) : ''),
  },
  computed: {
    userCanEditContract() {
      return this.contract.current_user && this.contract.current_user.can_edit
    },
    internalDocumentNumber() {
      return this.contract?.number || null
    },
    documentArchivingExpirationDate() {
      const expirationDate = this.contract?.state_trusted_archive?.document_expiration_date

      if (!expirationDate) {
        return null
      }

      return this.$t('contract.archive.expiration_date', {
        date: `${getFormattedDateByLocale({
          date: expirationDate,
        })}`?.replaceAll(' ', '&nbsp;'),
      })
    },
    documentInArchiveTrashDate() {
      const trashDate = this.contract?.trashed_at

      if (!trashDate) {
        return null
      }

      return this.$t(
        new Date(trashDate) >= new Date() ? 'contract.archive.trash_future' : 'contract.archive.trash_past',
        {
          date: getFormattedDateByLocale({
            date: trashDate,
          }),
        },
      )
    },
  },
  methods: {
    editContract() {
      return this.$emit('edit-contract')
    },
  },
}
</script>

<style lang="scss" scoped>
.contract-info-box {
  background-color: #f2f6fb;
  padding: 0 16px 0;

  &__row {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;

    span.row__title {
      text-align: left;
      font-size: 14px;
    }

    span.row__data {
      text-align: right;
      font-size: 14px;
      font-weight: bolder;
    }

    a {
      text-align: left;
      font-size: 14px;
      color: #666b71;
    }
  }
}
</style>
